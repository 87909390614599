body {background-color : black! important;overflow-y:scroll;overflow-x: hidden; /* Adjust based on navbar height */}
.bg-dark { background-color: black! important;}
.App{background-color : black;min-height: 100vh;}


/* NavbarComponent.css */
.logo {
  height: 60px; /* Adjust height as needed */
  margin-bottom: 20px;
  }
  a{color: #FFC745 !important;}
  .darks {
    --bs-bg-opacity: 1;
    background-color: black !important;
}
.flex-grow-1 {margin-top: 60px!important;}
.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color : black;
 overflow-x: hidden;
}
.bg-body-tertiarry {
background-color: black !important;
}
.video-responsive {
  width: 100%;   /* Video takes up full width of its container */
  max-width: 1500px; /* Set a max-width for larger screens */
  height: auto;  /* Maintain aspect ratio */
}

.content {
  flex: 1; /* This makes sure the content takes up the available space */
}

.footer {
  background: #f8f9fa;
  padding: 10px;
  text-align: center;
}
.my-15 {
 /*  margin-top: -1.8rem !important;*/
  /* margin-bottom: 1.5rem !important;*/
  height: 50vh !important;

}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .vh-101 {
    margin-top: -20px;
    height: 75vh; /* Adjust for smaller screens */
  }
}

/* Media query for larger screens */
@media (min-width: 1200px) {
  .vh-101 {
    overflow-y: hidden;
    height: 90vh; /* Adjust for larger screens */
  }
}
@media (min-width: 1000px) {
  .vh-101 {
    overflow-y: hidden;
    height: 80vh; /* Adjust for larger screens */
  }
}
.bg-body-tertiaryy {
  --bs-bg-opacity: 1;
  background-color: black !important;
}

.full-width-modal .modal-dialog {
  width: 100%;
  margin: 0;
}

.full-width-modal .modal-content {
  width: 100%;
  height: 100vh; /* Optional: Makes the modal full height */
}

.full-width-modal .modal-header,
.full-width-modal .modal-body,
.full-width-modal .modal-footer {
  width: 100%;
}
.slide-enter {
  transform: translateX(100%);
}

.slide-enter-active {
  transform: translateX(0);
  transition: transform 0.5s ease-in-out;
}

.slide-exit {
  transform: translateX(0);
}

.slide-exit-active {
  transform: translateX(100%);
  transition: transform 0.5s ease-in-out;
}
/* Tmage-gride slider */
/* src/ImageGridSlider.css */
/* src/Components/ImageGridSlider.css */
.image-grid-item {
  padding: 5px;
}

.carousel-item {
  display: flex;
  justify-content: center;
  align-items: center;
 
}

.carousel-item .container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.custom-prev,
.custom-next {
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: translateY(-50%);
  transition: background-color 0.3s ease;
}

.custom-prev::before,
.custom-next::before {
  content: '';
  width: 20px;
  height: 20px;
  border-top: 2px solid white;
  border-right: 2px solid white;
}

.custom-prev {
  left: 20px;
}

.custom-prev::before {
  transform: rotate(-135deg);
}

.custom-next {
  right: 20px;
}

.custom-next::before {
  transform: rotate(45deg);
}

.custom-prev:hover,
.custom-next:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

  .carousel-caption  h3 {
      font-size: 5.75rem;
      color: #FFC745;
  }

  /* Navbar modal pop up */
  .modal-body {
    background-color: black;
    position: relative;
    flex: 1 1 auto;
    padding: var(--bs-modal-padding);
}
.modal-fullscreen .modal-footer, .modal-fullscreen .modal-header {
  background-color: black;
  border-radius: 0;
  border:none;
}
.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: var(--bs-modal-header-padding);
  border-bottom: 0px;
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
      color: yellow !important;
      font-size: 30px !important;
      padding-right: var(--bs-navbar-nav-link-padding-x);
      padding-left: var(--bs-navbar-nav-link-padding-x);
      margin-bottom: 20px !important;
  }
}
.nav-link-spacing {
  padding: 0 1rem; /* Adjust as needed */
  transition: transform 0.1s ease, color 0.3s ease; /* Smooth transition */
}
.nav-link-spacing:hover {
  transform: scale(2.1)! important; /* Zoom in slightly */
  color: #007bff; /* Optional: Change text color on hover */
}
.w-58 {/* Adjust nav-space as needed */
  width: 58% !important;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: #fff !important; /* highlight on each page */
 background-color: #FFC745;
  font-size: 20px;
  border-radius: 20px;
}
.nav-link {
  color: #FFC745 !important;
  font-size: 25px! important;
}

.navbar-expand .navbar-nav .nav-link {
  font-size: 18px !important;
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}

.nav-link:focus, .nav-link:hover {
  color: #FFC745 !important;
  font-size: 25px;
}
a:hover {
  color: #FFC745 !important;
}
.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 120% !important;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}
/*mobile navbar */
@media only screen and (max-width: 600px) {
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-color: #fff;
    background-image: var(--bs-navbar-toggler-icon-bg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
  }
}
.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: #FFC745 !important;
  text-decoration: none;
  background: 0 0;
  border: 0;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}


 /* custom slider */

/* Custom slider */
.slider-container-fluid {
  width: 100vw;  /* Full viewport width */
  margin: 0 auto;
  overflow: hidden;
  padding: 0;
}

.slick-list {
  overflow: visible !important;  /* Allow overflow for previous/next images */
}

.slick-slide {
  transition: transform 0.5s ease-in-out;
  opacity: 0.6;
}

.slick-center {
  transform: scale(1.2);  /* Slight zoom for the center slide */
  opacity: 1;
  transition: transform 0.5s ease-in-out;  /* Smooth zoom transition */
}

.slider-item {
  position: relative;
  outline: none;
  padding: 60px;
}

.slider-item {
  background-image: url('./Images/pointer.png'), auto; /* Set custom cursor */
}

/* Optionally, if you want to maintain the default cursor for non-slider items */
.slider-item:hover {
  background-image: url('./Images/pointer.png'), pointer; /* Custom cursor on hover */
}

.slider-item img {
  width: 100%;  /* Make sure the image covers its container */
  border-radius: 0px;
  height: 50vh;
}

.slide-caption {
  position: absolute;
  bottom: 50px;  /* Start position at the bottom of the image */
  left: 48%;
  transform: translate(-50%, 20px);  /* Start slightly below the final position */
  opacity: 0;  /* Start as invisible */
  transition: transform 0.05s ease-in-out, opacity 0.05s ease-in-out;  /* Smooth transition */
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  width: 80%;  /* Set a width for the caption */
  max-width: 1000px;  /* Optional: Set a maximum width */
  white-space: nowrap;  /* Prevent text from wrapping to a new line */
}

.slide-caption h3 {
  font-size: 3.5rem;  /* Adjust font size */
  font-weight: 650;
  color:#FFC745;
  letter-spacing: 0.1rem
}

/* When the slide is in the center, apply the transition */
.slick-center .slide-caption {
  transform: translate(-50%, 0); /* Center the caption */
  opacity: 1;  /* Fade in */
  animation: slideIn 2.0s forwards;  /* Apply animation */
}

/* Keyframes for slide-in effect */
@keyframes slideIn {
  from {
    transform: translate(-50%, 100%);  /* Start from below */
    opacity: 0;  /* Start as invisible */
  }
  to {
    transform: translate(-50%, 0);  /* End at center */
    opacity: 1;  /* Fully visible */
  }
}

/* Slider buttons */
.slider-controls {
  display: flex;
  justify-content: center;  /* Center the buttons */
  margin-top: 20px;  /* Add some space above */
}

.slider-button {
  background-color: #007bff;  /* Bootstrap primary color */
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  margin: 0 10px;  /* Spacing between buttons */
  cursor: pointer;
  font-size: 1rem;  /* Font size */
  transition: background-color 0.3s;  /* Transition effect */
}

.slider-button:hover {
  background-color: #0056b3;  /* Darker shade on hover */
}

/* Media queries for responsivenessssssssssssssssssssssssssssssssss */
@media (max-width: 768px) {  /* Tablets and below */
  .slider-item {
    padding: 30px;  /* Reduce padding */
  }

  .slider-item img {
    height: auto;  /* Reduce height on smaller screens */
  }

  .slide-caption h3 {
    font-size: 2rem;  /* Smaller font size */
  }

  .slider-button {
    padding: 8px 16px;  /* Smaller buttons */
    font-size: 0.9rem;  /* Reduce button font size */
  }
}
@media (max-width: 1024px) {  /* Tablets and below */
  .slider-item {
   padding: 30px;  /* Reduce padding */
  }
  

  .slider-item img {
    height: auto;  /* Reduce height on smaller screens */
  }

  .slide-caption h3 {
    font-size: 2rem;  /* Smaller font size */
  }

  .slider-button {
    padding: 8px 16px;  /* Smaller buttons */
    font-size: 0.9rem;  /* Reduce button font size */
  }
  .my-15 {
    margin-top: 10.2rem !important;
  }
}
form {margin-bottom: 20px;}
@media (min-width: 1025px) {
 
  .caption {font-size: 30px;}
  .h6{font-size: 30px ! important;}
  .nav-link {
    text-align: center;
    color: #fff;
    margin:  6px ! important;
    font-size: 40px ! important;
}
}

@media (max-width: 480px) {  /* Mobile devices */
  .slider-item {
    margin-top: 50%;
    padding: 20px;  /* Further reduce padding */
  }
  form { margin-top: 40px;}
  .logo {
    height: 50px;
    margin-left: 30%;
    margin-bottom: 34px;
  } 

  .slider-item img {
    height: auto; /* Smaller height for mobile */
  }
  .navbar-expand .navbar-nav .nav-link {
    font-size: 15px !important;
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
}
h6 { margin-top: 12px! important; 
font-size: 12px! important;}
p{margin-top: 40px! important;}

  .slide-caption h3 {
    font-size: 1.5rem;  /* Even smaller font size */
  }

  .slider-button {
    padding: 6px 12px;  /* Smaller buttons for mobile */
    font-size: 0.8rem;  /* Further reduce button font size */
    margin: 0 5px;  /* Less margin between buttons */
  }

  .slider-controls {
    margin-top: 10px;  /* Less margin on mobile */
  }
  h3{ margin-bottom: -1.5rem! important;}
  .bg-body-tertiarry{display: none;}
}
/* parallax page  */

.gallery {
  position: relative;
  width: 100%;
}

/* Each pinned image section */
.pinned-image {
  position: sticky;
  top: 0; /* Pins the element to the top of the viewport */
  height: 81vh; /* Makes each image full viewport height */
  display: flex;
  justify-content: center;
  align-items: center;
  background: #000;
 
}

.pinned-image img {
  max-width: 100%;
  height: 100%;
  border: 0 none;
  vertical-align: middle;
  object-fit: cover; 

}

/* Responsive Styles */



/* responsiveness-mobile for portfolio  single page */
@media (max-width: 480px) {
  .pinned-image {
    height: 70vh;
  }
  .pinned-image img {
    height:auto; 
  }
  .caption {
    font-size: 12px; /* Even smaller text for phones */
    padding: 5px 10px;
  }
}
/* For tablets and smaller screens (portrait orientation) */
@media (max-width: 768px) {
  .pinned-image {
    height: 70vh; 
  }
}
/* For smaller laptops (screens from 1024px to 1280px) */
@media (max-width: 992px) {
  .pinned-image {
    height: 87vh; 
  }
}

@media (min-width: 1200px) {
  .pinned-image {
    height: 88vh; /* Adjust the height for larger screens */
  }
}


/* ---------- ended -------------*/

.parallexScroll{
  height: 80vh !important;
  overflow-y: auto;
}
.parallexScroll::-webkit-scrollbar {
  display: none;  /* Hide the scrollbar */
}

/* Styling for the scrollbar track */
.parallexScroll::-webkit-scrollbar-track {
  background: #333; /* Track background */
  color: #333;
  border-radius: 10px;
}

/* Styling for the scrollbar thumb (the draggable part) */
.parallexScroll::-webkit-scrollbar-thumb {
  background: #333; /* Thumb color */
  color: #333;
  border-radius: 10px;
}

/* Hover effect for the scrollbar thumb */
.parallexScroll::-webkit-scrollbar-thumb:hover {
  background: #333; /* Darker thumb color when hovered */
  color: #333;
}

/* Zoom in effect  */
.zoom-in {
  /*animation: zoomIn 0.5s ease-out forwards;*/
  animation: popUp 0.8s ease-out;

}

@keyframes zoomIn {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}


@keyframes popUp {
  0% {
    transform: scale(0); /* Start small */
    opacity: 0; /* Invisible */
  }
  50% {
    transform: scale(1.1); /* Slightly larger */
    opacity: 1; /* Fully visible */
  }
  100% {
    
    transform: scale(1); /* Normal size */
  }
}

@keyframes slideUp {
  0% {
    bottom: -100px;
    opacity: 0;
  }
  100% {
    bottom: 0;
    opacity: 1;
  }
}
/* Mobile devices*/
@media (max-width: 480px) {
  .justify-content-center {
    justify-content: left !important;
}
.w-58 {
  width: 100% !important;
}
.my-15 {
  margin-top: 2px !important;
}
}
/* ipad devices*/
/* For tablets and smaller screens (portrait orientation) */

 /* For tablets and smaller screens (portrait orientation) */
@media (max-width: 768px) {
  .my-15{
    margin-top: 1.2rem !important;   
}
}
.caption {
  width:100%;
  position: absolute;
  top: 40%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%); /* Adjust for perfect centering */
  text-align: center;
  color: #FFC745;
  padding: 15px 20px;
  border-radius: 8px; /* Optional: Rounded corners */
  opacity: 0;
  animation: slideUp 1s ease-out forwards;
}
/* SlideUp animation */
@keyframes slideUp {
  0% {
    opacity: 0;
    transform: translate(-50%, 0%); /* Start slightly below the center */
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%); /* End perfectly centered */
  }
}
/* In your custom CSS file */
/* Custom styles for navbar and modal */

.modal-header {
  background-color: #343a40; /* Dark background for the modal header */
  color: white;
}

.modal-body {
  padding: 20px;
}
.nav-link {
  /* Larger font size for menu items */
  text-align: center;
  color: #fff; 
  margin: 20px;               /* White color for the links */
}
.modal-header {
  border: none; /* Removes the border */
}
/* Custom CSS to align the close button to the right */
.modal-header .ml-auto svg {
  right: 20px;
  top: 10px;
}
.btn-link {color:#fff! important;}
.ms-button{margin-top: -48px;}
img, svg {
  color: #FFC745 ;
  vertical-align: middle;
}
p {
  color: #FFC745 ;
  margin-top: 0;
  margin-bottom: 1rem;
  font-size: 20px;
}
.vh-10{margin-top: 30px! important;}
.form-label {
  margin-bottom: .5rem;
  color: #FFC745;
}
button { border-radius: 0px! important; background-color: #FFC745;}
.form-control{border-radius: 0px! important;}
.mb-31 { margin-top:0px;}
svg a{  color: #FFC745 ;}
.btn-secondary{background-color: #FFC745! important; border-color:#FFC745! important ;}
.castCrewContainer {
  display: flex;
  justify-content: space-between; /* Space out the cast and crew sections */
  margin-top: 20px;
}

.castSection, .crewSection {
  width: 50%; /* Each section will take up half the width */
}

.castSection h6, .crewSection h6 {
  margin-bottom: 10px; /* Add spacing between cast/crew member names */
  font-size: 30px;
 
}
@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1120px! important;
  }
}
.btn-secondary {color : black! important }























